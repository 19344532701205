import { Route } from "react-router-dom";
import { TenantUsers, Settings } from "../pages";
import { RoleGaurd } from "../../../HoC/RoleGaurd";
import { AllSettingsRoutes } from "./AllSettingsRoutes";

const SettingsRouter = () => {
  return (
    <>
      <Route path={AllSettingsRoutes.index} index element={<Settings />} />

      <Route
        path={AllSettingsRoutes.tenant_users}
        index
        element={
          <RoleGaurd allowedRoles={["TENANT_OWNER"]}>
            <TenantUsers />
          </RoleGaurd>
        }
      />
    </>
  );
};

export default SettingsRouter;
