import { Route } from "react-router-dom";
import { StrategizeHome } from "../pages";
import { AllStrategizeRoutes } from "./AllStrategizeRoutes";

const StrategizeRouter = () => {
  return (
    <>
      <Route
        path={AllStrategizeRoutes.index}
        index
        element={<StrategizeHome />}
      />
    </>
  );
};

export default StrategizeRouter;
