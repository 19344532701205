import React from "react"
import "./styles.scss";

interface SelectProps {
    id: string;
    label: string;
    placeholder: string;
    value?: string;
    options: {label: string, value: string}[];
    onChange?: React.ChangeEventHandler<HTMLSelectElement>
}

const Select: React.FC<SelectProps> = ({ id, label, placeholder, value = "", options, onChange }) => {
    return (
        <div className="av-custom-select">
            <label htmlFor={id}>{label}</label>
            <select name={id} id={id} value={value} onChange={onChange}>
                <option selected disabled value="">
                    {placeholder}
                </option>

                {
                    options.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))
                }
            </select>
        </div>
    )
}

export default Select