import { Route } from "react-router-dom";
import { Compare } from "../pages";
import { AllCompareRoutes } from "./AllCompareRoutes";

const CompareRouter = () => {
  return (
    <>
      <Route path={AllCompareRoutes.index} index element={<Compare />} />
    </>
  );
};

export default CompareRouter;
