import { Route } from "react-router-dom";
import { AllCollaborateRoutes } from "./AllCollaborateRoutes";
import { CollaborateHome } from "../pages";

const CollaborateRouter = () => {
  return (
    <>
      <Route
        path={AllCollaborateRoutes.index}
        index
        element={<CollaborateHome />}
      />
    </>
  );
};

export default CollaborateRouter;
