import React, { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootState } from "../redux/store";
import { AllRouteConstants } from "../router/routes";
import { UserType } from "../interfaces/User";

export interface RequireAuthProps {
  children: ReactElement;
  allowedRoles: UserType[];
}

export const RoleGaurd: React.FC<RequireAuthProps> = ({
  children,
  allowedRoles,
}) => {
  const user = useSelector((state: IRootState) => state.user);

  const navigate = useNavigate();

  useEffect(() => {
    if (!allowedRoles.includes(user.userData?.role!)) {
      navigate(AllRouteConstants.main.dashboard.index);
    }
  }, [user]);

  return children;
};
