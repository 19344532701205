import axios from "axios";
import { API_URL } from "../config/env";
import { AvTokens } from "../interfaces/AvTokens";
import { CreateTenantUserInput } from "../interfaces/TenantUser";

class TenantUserService {
  fetchAll = (tokens: AvTokens) => {
    return axios.get(`${API_URL}/tenant-users`, {
      headers: {
        ...tokens,
      },
    });
  };

  fetchOne = (hash: string) => {
    return axios.get(`${API_URL}/tenant-user?hash=${hash}`, {});
  };

  create = (data: CreateTenantUserInput, tokens: AvTokens) => {
    return axios.post(`${API_URL}/tenant-users`, data, {
      headers: {
        ...tokens,
      },
    });
  };
}

const tenantUserService = new TenantUserService();
export default tenantUserService;
