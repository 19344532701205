import { Route } from "react-router-dom";
import ComponentWithTitleDisplay from "../../../components/ComponentWithTitle";
import { AllRouteConstants } from "../../../router/routes";
import { ConfirmAccount, Login } from "../pages";

const AuthRoutes = () => {
  return (
    <>
      <Route
        index
        element={
          <ComponentWithTitleDisplay component={<Login />} title="Login" />
        }
      />
      <Route
        path={AllRouteConstants.auth.login}
        element={
          <ComponentWithTitleDisplay component={<Login />} title="Login" />
        }
      />
      <Route
        path={AllRouteConstants.auth.createTenant}
        element={
          <ComponentWithTitleDisplay
            component={<ConfirmAccount />}
            title="Confirm Account"
          />
        }
      />
    </>
  );
};

export default AuthRoutes;
