import React, { useEffect } from "react";
import logo from "../../../assets/images/AllovanceLogoDark.svg";
import Button from "../../../components/ui/button";
import Input from "../../../components/ui/input/Input";
import useApi from "../../../hooks/useApi";
import { useForm } from "../../../hooks/useForm";
import {
  ConfirmAccountDetail,
  ConfirmAccountInput,
} from "../../../interfaces/ConfirmAccountInput";
import authService from "../../../services/authenticationService/AuthService";
import { emptyValidator } from "../../../utils/validators/emptyValidator";
import { passwordValidator } from "../../../utils/validators/passwordValidator";
import { User } from "../../../interfaces/User";
import { useDispatch } from "react-redux";
import { authActions } from "../../../redux/authenticatedUserSlice";
import { LoginOutput } from "../../../interfaces/LoginInput";

const userDetails = (hash: string) => authService.getUserDetails(hash);

const confirmAccount = (data: ConfirmAccountInput) =>
  authService.confirmAccount(data);

export const ConfirmAccount = () => {
  const dispatch = useDispatch();

  const getUserDetailsRequest = useApi<ConfirmAccountDetail, string>(
    userDetails
  );

  const hash = new URLSearchParams(window.location.search).get("hash");

  const getDetails = async (hash: string) => {
    try {
      const details = await getUserDetailsRequest.request(hash);
      console.log(details);
    } catch (error) {}
  };

  useEffect(() => {
    if (hash) getDetails(hash);
  }, []);

  const confirmAccountForm = useForm<ConfirmAccountInput>(
    { password: "", confirmPassword: "", hash: hash as string },
    {
      password: passwordValidator,
      confirmPassword: emptyValidator,
    }
  );

  const confirmAccountRequest = useApi<LoginOutput, ConfirmAccountInput>(
    confirmAccount
  );

  const handleChangePassword: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    confirmAccountForm.onChange("password", e.target.value);
  };

  const handleChangeConfirmPassword: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    confirmAccountForm.onChange("confirmPassword", e.target.value);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (confirmAccountRequest.loading) return;

    confirmAccountForm.resetFormErrors();

    confirmAccountRequest.reset();

    const valid = confirmAccountForm.validate();

    if (valid && getUserDetailsRequest.data) {
      if (
        confirmAccountForm.form.password !==
        confirmAccountForm.form.confirmPassword
      ) {
        return;
      }

      try {
        const user = await confirmAccountRequest.request({
          ...confirmAccountForm.form,
        });

        console.log(user);
        if (user) {
          dispatch(authActions.signin(user));
        }
      } catch (error) {}
    }
  };

  return (
    <div className="av-login-page">
      {getUserDetailsRequest.loading && <div>Loading...</div>}

      {!getUserDetailsRequest.loading &&
        getUserDetailsRequest.error &&
        Object.keys(getUserDetailsRequest.error).length > 0 && (
          <div>
            {typeof getUserDetailsRequest.error === "string"
              ? getUserDetailsRequest.error
              : JSON.stringify(getUserDetailsRequest.error)}
          </div>
        )}

      {!getUserDetailsRequest.loading &&
        (!getUserDetailsRequest.error ||
          Object.keys(getUserDetailsRequest.error).length === 0) && (
          <form className="av-login-page__form" onSubmit={handleSubmit}>
            <img src={logo} alt="logo" />

            <h1>Create New Allovance Account</h1>
            <span>
              Login Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
              do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </span>

            <div className="av-login-page__form__input-container">
              <Input
                label="Tenant Name"
                id="tenantName"
                value={getUserDetailsRequest.data?.tenantName || ""}
                readOnly={true}
              />

              <Input
                label="Tenant Owner"
                id="tenantOwnerName"
                value={getUserDetailsRequest.data?.tenantOwnerName}
                readOnly={true}
              />

              <Input
                label="Tenant Email"
                id="tenantOwnerEmail"
                value={getUserDetailsRequest.data?.tenantOwnerEmail}
                readOnly={true}
              />

              <Input
                label="Create Password"
                id="password"
                value={confirmAccountForm.form.password}
                onChange={handleChangePassword}
                type="password"
                required={true}
                error={confirmAccountForm.formErrors.password}
              />

              <Input
                label="Confirm Password"
                id=""
                value={confirmAccountForm.form.confirmPassword}
                onChange={handleChangeConfirmPassword}
                type="password"
                required={true}
                error={
                  confirmAccountForm.form.confirmPassword !==
                  confirmAccountForm.form.password
                    ? "Passwords Don't Match"
                    : null
                }
              />
            </div>

            <Button
              loading={confirmAccountRequest.loading}
              label={"Create Account and Join Workspace"}
              type="submit"
            />

            <div className="av-login-page__form__extras">
              <span>
                <a href="#">Privacy Policy</a>
              </span>{" "}
              &#x2022;{" "}
              <span>
                <a href="#">Terms of Use</a>
              </span>
            </div>
          </form>
        )}
    </div>
  );
};
