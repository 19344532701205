import { Route } from "react-router-dom";
import { QuantifyHome } from "../pages";
import { AllQuantifyRoutes } from "./AllQuantifyRoutes";

const QuantifyRouter = () => {
  return (
    <>
      <Route path={AllQuantifyRoutes.index} index element={<QuantifyHome />} />
    </>
  );
};

export default QuantifyRouter;
