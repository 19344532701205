import { DashboardDrawerType } from "./types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./DashboardDrawerStyles.scss";
import { toggleSideBar } from "../../../redux/sidebarSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { authActions } from "../../../redux/authenticatedUserSlice";
import NavItem from "./NavItem";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { AllSettingsRoutes } from "../../../features/settings/routes/AllSettingsRoutes";
import { AllDashboardRoutes } from "../../../features/dashboard/routes/AllDashboardRoutes";
import { AllStrategizeRoutes } from "../../../features/strategize/routes/AllStrategizeRoutes";
import { AllCompareRoutes } from "../../../features/compare/routes/AllCompareRoutes";
import { AllQuantifyRoutes } from "../../../features/quantify/routes/AllQuantifyRoutes";
import { AllScoreRoutes } from "../../../features/score/routes/AllScoreRoutes";
import { AllSelectRoutes } from "../../../features/select/routes/AllSelectRoutes";
import { AllCollaborateRoutes } from "../../../features/collaborate/routes/AllCollaborateRoutes";

const DashboardDrawer = (props: DashboardDrawerType) => {
  const dispatch = useDispatch();

  const user = useAppSelector((state) => state.user);

  const { userData } = user!;

  return (
    <div
      id="drawer_container"
      className={`av-dashboard-drawer`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="av-dashboard-drawer__close">
        <i className="ri-close-line"></i>
      </div>

      <div className="wrapper">
        <div className="av-dashboard-drawer__nav-items">
          {userData?.role === "TENANT_OWNER" && (
            <NavItem
              icon={<FontAwesomeIcon icon={icon({ name: "user" })} />}
              title="User Management"
              primaryRoute={AllSettingsRoutes.tenant_users}
            />
          )}

          {userData?.role === "TENANT_USER" && (
            <>
              <NavItem
                icon={<FontAwesomeIcon icon={icon({ name: "dashboard" })} />}
                title="Dashboard"
                primaryRoute={AllDashboardRoutes.index}
              />

              <NavItem
                icon={<FontAwesomeIcon icon={icon({ name: "sitemap" })} />}
                title="Strategize"
                primaryRoute={AllStrategizeRoutes.index}
              />

              <NavItem
                icon={<FontAwesomeIcon icon={icon({ name: "sliders" })} />}
                title="Compare"
                primaryRoute={AllCompareRoutes.index}
              />

              <NavItem
                icon={<FontAwesomeIcon icon={icon({ name: "chart-line" })} />}
                title="Quantify"
                primaryRoute={AllQuantifyRoutes.index}
              />

              <NavItem
                icon={<FontAwesomeIcon icon={icon({ name: "circle-check" })} />}
                title="Score"
                primaryRoute={AllScoreRoutes.index}
              />

              <NavItem
                icon={
                  <FontAwesomeIcon
                    icon={icon({ name: "circle", style: "regular" })}
                  />
                }
                title="Select"
                primaryRoute={AllSelectRoutes.index}
              />

              <div className="spacer" />

              <NavItem
                icon={
                  <FontAwesomeIcon
                    icon={icon({ name: "users-between-lines" })}
                  />
                }
                title="Collaborate"
                primaryRoute={AllCollaborateRoutes.index}
              />

              <NavItem
                icon={
                  <FontAwesomeIcon
                    icon={icon({ name: "file", style: "regular" })}
                  />
                }
                title="Reports"
                primaryRoute={AllSelectRoutes.index}
              />
            </>
          )}
        </div>

        <div className="av-dashboard-drawer__nav-items">
          <NavItem
            icon={<FontAwesomeIcon icon={icon({ name: "gear" })} />}
            title="Settings"
            primaryRoute={AllSettingsRoutes.index}
          />

          <div className="av-nav-item">
            <div className="icon">
              <FontAwesomeIcon
                icon={icon({ name: "file-lines", style: "regular" })}
              />
            </div>

            <div className="title">User Guide</div>
          </div>

          <div className="spacer" />

          <div
            className="av-drawer-user"
            onClick={() => {
              dispatch(toggleSideBar(false));
              dispatch(authActions.signout());
            }}
          >
            <div className="img"></div>

            <div className="title">
              {userData?.given_name ?? "Sheriff"}{" "}
              {userData?.family_name ?? "Farlaghn"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardDrawer;
