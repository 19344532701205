/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { TenantUser } from "../interfaces/TenantUser";


interface TenantUserSlice {
  data: TenantUser[];
  initialized: boolean;
}

const initialState: TenantUserSlice = {
  data: [],
  initialized: false
}

export const tenantUsersSlice = createSlice({
  name: "tenant_users",
  initialState,
  reducers: {
    initialize: (state, { payload }) => {
      state.data = payload;
      state.initialized = true;
    },

    add: (state, { payload }) => {
      state.data = [...state.data, payload];
    }
  },
});

export const tenantUserActions = tenantUsersSlice.actions;

export default tenantUsersSlice.reducer;
