import { BrowserRouter, Routes, Route } from "react-router-dom";
// scroll
import Scroll from "../components/scrollToTop/ScrollToTop";
import { AllRouteConstants } from "./routes";
import AuthLayout from "../layouts/authLayout/AuthLayout";
import AuthRoutes from "../features/auth/routes/Routes";
import DashboardHomeRoutes from "../features/dashboard/routes/DashboardRouter";
import { RequireAuth } from "../HoC/RequireAuth";
import DashboardLayout from "../layouts/dashboardLayout/DashboardLayout";
import { NoRequireAuth } from "../HoC/NoRequireAuth";
import SettingsRouter from "../features/settings/routes/SettingsRouter";
import CompareRouter from "../features/compare/routes/CompareRouter";
import StrategizeRouter from "../features/strategize/routes/StrategizeRouter";
import QuantifyRouter from "../features/quantify/routes/QuantifyRoutes";
import ScoreRouter from "../features/score/routes/ScoreRouter";
import SelectRouter from "../features/select/routes/SelectRouter";
import CollaborateRouter from "../features/collaborate/routes/StrategizeRouter";

function Router() {
  return (
    <BrowserRouter>
      <Scroll />
      <Routes>
        {/** [AUTH] */}
        <Route
          path={AllRouteConstants.auth.index}
          element={
            <NoRequireAuth>
              <AuthLayout />
            </NoRequireAuth>
          }
        >
          {AuthRoutes()}
        </Route>

        {/** [OTHERS] */}
        <Route
          path={AllRouteConstants.main.dashboard.index}
          element={
            <RequireAuth>
              <DashboardLayout />
            </RequireAuth>
          } // TODO: REPLACE WITH VALID ROUTE WRAPPER
        >
          {DashboardHomeRoutes()}

          {CompareRouter()}

          {QuantifyRouter()}

          {ScoreRouter()}

          {SelectRouter()}

          {StrategizeRouter()}

          {SettingsRouter()}

          {CollaborateRouter()}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
