export const AllRouteConstants = {
  auth: {
    index: "/auth",
    login: "/auth/signin",
    createTenant: "/auth/confirm-account",
  },
  main: {
    dashboard: {
      index: "/",
      dashboard: "/dashboard",
    },
  },
};
