import { useEffect, useState } from "react";
import Button from "../../../components/ui/button";
import SearchInput from "../../../components/ui/SearchInput";
import AddTenantUser from "../components/AddTenantUser";
import TenantUserTable from "../components/TenantUserTable";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../redux/store";
import useApi from "../../../hooks/useApi";
import { TenantUser } from "../../../interfaces/TenantUser";
import { AvTokens } from "../../../interfaces/AvTokens";
import tenantUserService from "../../../services/TenantUserService";
import { tenantUserActions } from "../../../redux/tenantUsersSlice";
import { TableSkeletonLoader } from "../../../components/ui/skeletonLoader/TableSkeletonLoader";

const fetchAllTenantUsers = (tokens: AvTokens) =>
  tenantUserService.fetchAll(tokens);

export const TenantUsers = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const { initialized, data } = useSelector(
    (state: IRootState) => state.tenant_users
  );
  const { tokens } = useSelector((state: IRootState) => state.user);

  const fetchTenantApi = useApi<TenantUser[], AvTokens>(fetchAllTenantUsers);

  const executeFetchAllTenants = async () => {
    const result = await fetchTenantApi.request(tokens || undefined);
    console.log(result);

    if (result && Array.isArray(result)) {
      dispatch(tenantUserActions.initialize(result));
    }
  };

  useEffect(() => {
    if (
      tokens &&
      Object.keys(tokens).length >= 3 &&
      !initialized &&
      !fetchTenantApi.loading
    ) {
      executeFetchAllTenants();
    }
  }, [tokens, initialized]);

  return (
    <>
      <AddTenantUser isOpen={isOpen} setIsOpen={setIsOpen} />

      <div className="av-tenant-users animate__animated animate__fadeIn">
        <div className="av-tenant-users__table-container">
          <div className="controls">
            <div className="controls__item">
              <span>
                <i className="ri-information-line"></i>
              </span>

              <span>Tenant Users</span>
            </div>

            <div className="controls__item">
              <SearchInput placeholder={"Search Users"} />

              <Button
                label={
                  <>
                    <i className="ri-upload-2-line"></i>&nbsp;Upload CSV
                  </>
                }
                variant="secondary"
              />

              <Button
                label={
                  <>
                    <i className="ri-add-line"></i>&nbsp;Add Users
                  </>
                }
                onClick={() => setIsOpen(true)}
              />
            </div>
          </div>

          <div className="spacer"></div>

          {/** Tenant Users Table */}

          {fetchTenantApi.loading ? (
            <TableSkeletonLoader
              columnValues={[
                "User",
                "User Role",
                "User Email",
                "Last Login",
                "",
              ]}
              rowNo={4}
            />
          ) : (
            <TenantUserTable users={data} />
          )}
        </div>
      </div>
    </>
  );
};
