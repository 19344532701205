import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toggleSideBar } from "../../../redux/sidebarSlice";
import { IRootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "../../../hooks/useAppSelector";

interface NavItemProps {
  activePaths?: string[];
  primaryRoute: string;
  icon: JSX.Element;
  title: string;
}

const NavItem: React.FC<NavItemProps> = ({
  icon,
  title,
  activePaths = [],
  primaryRoute,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { sidebarOpened, isMobile } = useAppSelector((state) => state.sidebar);

  const handleClick = () => {
    if (isMobile && sidebarOpened) {
      dispatch(toggleSideBar(false));
    }

    navigate(primaryRoute);
  };

  return (
    <div
      className={`av-nav-item ${
        activePaths.includes(pathname) || primaryRoute === pathname
          ? "av-nav-item-active"
          : undefined
      }`}
      onClick={handleClick}
    >
      <div className="icon">{icon}</div>

      <div className="title">{title}</div>
    </div>
  );
};

export default NavItem;
