import axios from "axios";
import { API_URL } from "../../config/env";
import { ConfirmAccountInput } from "../../interfaces/ConfirmAccountInput";
import { LoginInput } from "../../interfaces/LoginInput";
import { ResetPasswordInput } from "../../interfaces/ResetPasswordInput";
import { VerifySignInInput } from "../../interfaces/VerifySignInInput";

class AuthService {
  login = (data: LoginInput) => {
    return axios.post(`${API_URL}/auth/signin`, data);
  };

  logout = () => {
    return axios.get(`${API_URL}/auth/signout`);
  };

  verifySignIn = (data: VerifySignInInput) => {
    return axios.post(`${API_URL}/auth/signin/verify`, data);
  };

  requestpasswordreset = (email: string) => {
    return axios.get(`${API_URL}/auth/forgot-password?email=${email}`);
  };

  confirmpasswordreset = (data: ResetPasswordInput) => {
    console.log(data);
    return axios.post(`${API_URL}/auth/forgot-password`, data);
  };
  getUserDetails = (hash: string) => {
    return axios.get(
      `${API_URL}/tenants/confirm-account-details?hash=${hash} `
    );
  };
  confirmAccount = (data: ConfirmAccountInput) => {
    return axios.post(`${API_URL}/auth/confirm-account`, data);
  };
}

const authService = new AuthService();
export default authService;
