import authenticatedUserReducer from "./authenticatedUserSlice";
import sidebarReducer from "./sidebarSlice";
import tenantUserReducer from "./tenantUsersSlice";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";

// Reducers
const rootReducer = combineReducers({
  user: authenticatedUserReducer,
  sidebar: sidebarReducer,
  tenant_users: tenantUserReducer
});

// Redux Persistor Configuration
const persistConfig = {
  key: "root",
  version: 1,
  storage: storage,
  whitelist: ['user']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux Store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] as any,
    },
  }),
});
export const persistor = persistStore(store);

export type IRootState = ReturnType<typeof store.getState>;
