/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { AvTokens } from "../interfaces/AvTokens";
import { LoginOutput, UserData } from "../interfaces/LoginInput";

interface AuthSlice {
  tokens: AvTokens | null;
  userData: UserData | null;
}

const initialState: AuthSlice = {
  tokens: null,
  userData: null,
};

export const authenticatedUserSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    signin: (state, { payload }: { payload: LoginOutput }) => {
      state.tokens = {
        "X-accToken": payload.access_token,
        "X-idToken": payload.token,
        "X-refToken": payload.refresh_token,
      };
      state.userData = payload.user;
    },
    signout: (state) => {
      state.tokens = null;
      state.userData = null;
    },
  },
});

export const authActions = authenticatedUserSlice.actions;

export default authenticatedUserSlice.reducer;
