import "./DashboardHeader.scss";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/AllovanceLogoDark.svg"
import { IRootState } from "../../../redux/store";
import { toggleSideBar } from "../../../redux/sidebarSlice";
import Hamburger from "hamburger-react";

const DashboardHeader = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sidebarOpened } = useSelector((state: IRootState) => state.sidebar);

  const handleOpenSide = () => {
    dispatch(toggleSideBar(!sidebarOpened));
  };

  const { pathname } = useLocation();

  const getRouteMap = () => {
    const routeSplit = pathname.split("/");
    routeSplit.shift();

    const onClick = (idx: number) => () => {
        if (idx === 0) {
            navigate(routeSplit[idx].replaceAll(" ", "-").toLowerCase());
        } else {
            const routes = []
            for (let index = 0; index <= idx; index++) {
                const element = routeSplit[index].replaceAll(' ', '-').toLowerCase();

                routes.push(element);
            }
            navigate(routes.join("/"));
        }
    } 

    return (
      routeSplit.map((item, idx) => {
          return <span key={`route-${idx}`} onClick={onClick(idx)}>{item.replaceAll("-", " ")}{idx != routeSplit.length -1 ? <i className="ri-arrow-right-s-line"></i> : null}</span>
      })
    );
  }

  return (
    <header className="av-dashboard-header">
      <div className="hamburger">
        <Hamburger toggled={sidebarOpened} size={20} color="#000" toggle={handleOpenSide} />
      </div>

      <img src={logo} alt="Logo" />

      <div className="route-map">{getRouteMap()}</div>

      <div className="notification">
          <i className="ri-notification-3-line"></i>
      </div>
    </header>
  );
};

export default DashboardHeader;
