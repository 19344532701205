import { Route } from "react-router-dom";
import { ScoreHome } from "../pages";
import { AllScoreRoutes } from "./AllScoreRoutes";

const ScoreRouter = () => {
  return (
    <>
      <Route path={AllScoreRoutes.index} index element={<ScoreHome />} />
    </>
  );
};

export default ScoreRouter;
