import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import Button from "../../../../components/ui/button";
import Input from "../../../../components/ui/input/Input";
import Select from "../../../../components/ui/select";
import useApi from "../../../../hooks/useApi";
import { useForm } from "../../../../hooks/useForm";
import { AvTokens } from "../../../../interfaces/AvTokens";
import {
  CreateTenantUserInput,
  TenantUser,
} from "../../../../interfaces/TenantUser";
import { authActions } from "../../../../redux/authenticatedUserSlice";
import { IRootState } from "../../../../redux/store";
import { tenantUserActions } from "../../../../redux/tenantUsersSlice";
import tenantUserService from "../../../../services/TenantUserService";
import { emailValidator } from "../../../../utils/validators/emailValidator";
import { emptyValidator } from "../../../../utils/validators/emptyValidator";
import AuthError from "../../../auth/components/AuthError/AuthError";
import "./styles.scss";
import { toast } from "react-hot-toast";

interface AddTenantUserProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const groupOptions = [
  { label: "Model Owner", value: "MODEL_OWNER" },
  { label: "User", value: "USER" },
];

const addTenantUser = (data: {
  tokens: AvTokens;
  input: CreateTenantUserInput;
}) => tenantUserService.create(data.input, data.tokens);

const AddTenantUser: React.FC<AddTenantUserProps> = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { tokens } = useSelector((state: IRootState) => state?.user);

  const addTenantUserRef = useRef(null);

  const addTenantUserForm = useForm<CreateTenantUserInput>(
    { description: "", email: "", firstName: "", jobTitle: "", lastName: "" },
    {
      email: emailValidator,
      firstName: emptyValidator,
      lastName: emptyValidator,
    }
  );

  const addTenantUserApi = useApi<
    { data: TenantUser },
    { tokens: AvTokens; input: CreateTenantUserInput }
  >(addTenantUser);

  const handleFormFieldChage: React.ChangeEventHandler<
    HTMLInputElement | HTMLSelectElement
  > = (e) => {
    addTenantUserForm.onChange(
      e.target.name as keyof CreateTenantUserInput,
      e.target.value
    );
  };

  useEffect(() => {
    if (
      addTenantUserApi.error?.code > 400 &&
      addTenantUserApi.error?.code <= 403
    ) {
      dispatch(authActions.signout());
    }
  }, [addTenantUserApi.error]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    console.log(tokens, addTenantUserForm.form);

    if (addTenantUserApi.loading) return;

    addTenantUserForm.resetFormErrors();
    addTenantUserApi.reset();

    const valid = addTenantUserForm.validate();

    if (valid && tokens) {
      addTenantUserForm.resetFormErrors();
      try {
        const tenantUser = await addTenantUserApi.request({
          input: addTenantUserForm.form,
          tokens,
        });

        if (tenantUser) {
          dispatch(tenantUserActions.add(tenantUser.data));
          addTenantUserForm.reset();
          addTenantUserApi.reset();
          toast("Tenant Successfully Created");
          setIsOpen(false);
        }
      } catch (error) {
        console.log("[ERROR]", error);
      }
    }
  };

  return (
    <CSSTransition
      nodeRef={addTenantUserRef}
      in={isOpen}
      timeout={500}
      unmountOnExit
      classNames={"av-add-tenant-user"}
    >
      <div ref={addTenantUserRef} className="av-add-tenant-user">
        <div
          className="av-add-tenant-user__close"
          onClick={() => setIsOpen(false)}
        >
          <i className="ri-close-line"></i>
        </div>

        <div
          className={`av-add-tenant-user__content ${
            isOpen ? "slide-in-right" : "slide-out-right"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="x-div">
            <h3>Add New User</h3>

            <div className="x-text-content">
              Input your text here! The text element is intended for longform
              copy that could potentially include multiple paragraphs.
            </div>

            <div className="spacer"></div>

            <form id="addTenantUserForm" onSubmit={handleSubmit}>
              <Input
                id="firstName"
                label="First Name"
                value={addTenantUserForm.form.firstName}
                onChange={handleFormFieldChage}
              />

              <Input
                id="lastName"
                label="Last Name"
                value={addTenantUserForm.form.lastName}
                onChange={handleFormFieldChage}
              />

              <Input
                id="email"
                label="Tenant Email"
                value={addTenantUserForm.form.email}
                onChange={handleFormFieldChage}
              />

              <Input
                id="jobTitle"
                label="Job Title"
                value={addTenantUserForm.form.jobTitle}
                onChange={handleFormFieldChage}
              />

              <Input
                id="description"
                label="Description"
                value={addTenantUserForm.form.description}
                onChange={handleFormFieldChage}
              />

              <Select
                id="group"
                label="Group"
                placeholder="Select User Group"
                value={addTenantUserForm.form.group || ""}
                options={groupOptions}
                onChange={handleFormFieldChage}
              />
            </form>
          </div>

          <div className="x-div">
            <AuthError error={addTenantUserApi.error.error?.message} />

            <Button
              type="submit"
              formId="addTenantUserForm"
              loading={addTenantUserApi.loading}
              label={"Add User To Tenant"}
            />
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default AddTenantUser;
