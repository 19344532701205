import { Route } from "react-router-dom";
import { Dashboard } from "../pages";
import { AllDashboardRoutes } from "./AllDashboardRoutes";

const DashboardHomeRoutes = () => {
  return (
    <>
      <Route path={AllDashboardRoutes.index} index element={<Dashboard />} />

      <Route
        path={AllDashboardRoutes.dashboard}
        index
        element={<Dashboard />}
      />
    </>
  );
};

export default DashboardHomeRoutes;
