import React, { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { IRootState } from "../redux/store";
import { AllRouteConstants } from "../router/routes";
import { useAppSelector } from "../hooks/useAppSelector";

export interface NoRequireAuthProps {
  children: ReactElement;
}

export const NoRequireAuth: React.FC<NoRequireAuthProps> = ({ children }) => {
  const user = useAppSelector((state) => state.user);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    if (user.tokens || user.userData) {
      navigate(AllRouteConstants.main.dashboard.index);
    }
  }, [user, pathname]);

  return children;
};
