import React from "react";
import Button from "../../../../components/ui/button";
import "./styles.scss";
import { TenantUser } from "../../../../interfaces/TenantUser";

interface TenantUserTableProps {
    users: TenantUser[]
}

const TenantUserTable: React.FC<TenantUserTableProps> = ({ users }) => {
    return (
        <div className="av-tenant-user-table">
            <div className="av-tenant-user-table__card av-tenant-user-table__header">
                <div className="x-row-inner">
                    <div className="x-col">
                        <span>User</span>
                    </div>

                    <div className="x-col">
                        <span>User Role</span>
                    </div>

                    <div className="x-col">
                        <span>User Email</span>
                    </div>

                    <div className="x-col">
                        <span>Last Login</span>
                    </div>

                    <div className="x-col"></div>
                </div>
            </div>

            {users.map((data) => (
                <div key={data.tenantUserEmail} className="av-tenant-user-table__card av-tenant-user-table__item">
                    <div className="x-row-inner">
                        <div className="x-col">
                            <span>{`${data.firstName} ${data.lastName}`}</span>
                        </div>

                        <div className="x-col">
                            <span>{data.group || "-"}</span>
                        </div>

                        <div className="x-col">
                            <span>{data.tenantUserEmail}</span>
                        </div>

                        <div className="x-col">
                            <span>{new Date().toDateString()}</span>
                        </div>

                        <div className="x-col">
                            <Button variant="secondary" label={<><i className="ri-settings-2-fill"></i>&nbsp;Edit User</>} />
                        </div>
                    </div>
                </div>
            ))}
            
        </div>
    )
}

export default TenantUserTable;