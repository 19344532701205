import { Route } from "react-router-dom";
import { SelectHome } from "../pages";
import { AllSelectRoutes } from "./AllSelectRoutes";

const SelectRouter = () => {
  return (
    <>
      <Route path={AllSelectRoutes.index} index element={<SelectHome />} />
    </>
  );
};

export default SelectRouter;
