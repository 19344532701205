import "./DashboardLayout.scss";
import DashboardLayoutDesktop from "./DashboardLayoutDesktop";
import DashboardLayoutMobile from "./DashboardLayoutMobile";
import { Toaster } from "react-hot-toast";
const DashboardLayout = () => {
  return (
    <div className="av-dashboard-layout">
      <DashboardLayoutDesktop />
      <Toaster position="top-center" reverseOrder={false} />
      <DashboardLayoutMobile />
    </div>
  );
};

export default DashboardLayout;
