import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import 'remixicon/fonts/remixicon.css';
import 'animate.css';
import { toggleMobileState } from "./redux/sidebarSlice";
import Router from "./router/Router";

function App() {
  const [screenSize, setScreenSize] = useState<number | null>(null);
  const dispatch = useDispatch();

  // Setting the Screen Size State whenever the screen is resized
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Toggling the isMobile State whenever the screensize changes
  useEffect(() => {
    if (screenSize! <= 980) {
      dispatch(toggleMobileState(true));
    } else {
      dispatch(toggleMobileState(false));
    }
  }, [screenSize]);

  return <Router />;
}

export default App;
